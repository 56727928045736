import React from 'react';
import {
  TextField,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import CurrencyField from '../utils/CurrencyField';

import List from '../designSystem/react-admin/list/List';
import DateField from '../designSystem/react-admin/DateField';
import { DatagridConfigurable } from '../designSystem/react-admin/Datagrid';
import { useConstantContext } from '../ConstantsContext';
import TitleH1 from '../designSystem/TitleH1';

import UnidentifiedListButtons from './UnidentifiedListButtons';

import useHasUserPermissions from '../hooks/useHasUserPermissions';

export const getUnidentifiedAccountListTitle = (t) => t('Unidentified payments');

const UnidentifiedAccountList = () => {
  const { t } = useTranslation();
  const { constants } = useConstantContext();
  const { ledgerConstants, userActions } = constants;

  const canEdit = useHasUserPermissions(
    constants,
    userActions.MANAGE_UNIDENTIFIED_PAYMENTS,
  );

  return (
    <List
      title={<TitleH1 title={getUnidentifiedAccountListTitle(t)} />}
      filter={{
        balance: { $lt: -ledgerConstants.ROUNDING_THRESHOLD },
      }}
      sort={{ field: 'createdAt', order: 'DESC' }}
      disableSyncWithLocation
      appLocation="home.unidentified-account"
    >
      <DatagridConfigurable
        bulkActionButtons={canEdit ? <UnidentifiedListButtons /> : false}
        rowClick="edit"
        rightAlignedColumns={['amount']}
      >
        <DateField source="createdAt" label={t('Date')} showTime emptyText="-" />
        <TextField source="reference" sortable={false} emptyText="-" />
        <TextField source="payerName" label={t('Payer name')} sortable={false} emptyText="-" />
        <CurrencyField source="amount" currencySource="currency" sortable={false} />
      </DatagridConfigurable>
    </List>
  );
};

export default UnidentifiedAccountList;
