import React from 'react';
import {
  DateField,
  TextField,
  WrapperField,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import Datagrid from '../designSystem/react-admin/Datagrid';

import TransactionField from '../utils/TransactionField';
import CurrencyField from '../utils/CurrencyField';
import MarkAsUnidentifiedButton from '../utils/MarkAsUnidentifiedButton';

import OrderLink from './OrderLink';

const TransactionGrid = () => {
  const { t } = useTranslation();
  return (
    <Datagrid
      bulkActionButtons={false}
      variant="blueBackground"
      rightAlignedColumns={['amount', 'action', 'subActions']}
    >
      <TransactionField label={t('Type')} source="origin.type" choicesSource="transactionOrigins" />
      <DateField source="date" label={t('Date')} emptyText="-" />
      <TextField source="payinDetails.payerName" label={t('Payer name')} emptyText="-" />
      <CurrencyField source="amount" currencySource="currency" label={t('Amount')} coefficient={-1} />
      <WrapperField label={t('Order number/reference')} source="action">
        <OrderLink />
      </WrapperField>
      <WrapperField label={false} source="subActions">
        <MarkAsUnidentifiedButton />
      </WrapperField>
    </Datagrid>
  );
};

export default TransactionGrid;
