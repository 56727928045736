import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import Typography from '@mui/material/Typography';

import { useNotify, useRecordContext } from 'react-admin';

import ModalButton from '../designSystem/ModalButton';

import useCustomRpc from '../hooks/useCustomRpc';

const BalanceInSettlementButton = () => {
  const record = useRecordContext();
  const { t } = useTranslation();
  const notify = useNotify();

  const showNotification = useCallback(() => {
    notify(
      t('The billing was successfully charged to the settlement account.'),
      { autoHideDuration: 5000 },
    );
  }, [notify, t]);

  const {
    mutate: balanceInSettlement,
    isLoading,
  } = useCustomRpc({
    httpMethod: 'PUT',
    path: `account/${record.id}/balance-in-settlement`,
    shouldRefresh: true,
    onSuccess: showNotification,
    errorMessage: t('Could not balance in settlement'),
  });

  const handleClick = useCallback(() => {
    balanceInSettlement();
  }, [balanceInSettlement]);

  if (record.partnerBillingId) return null;

  return (
    <ModalButton
      modalTitle={t('Charge in settlement')}
      openButtonLabel={t('Charge in settlement')}
      onClick={handleClick}
      disabled={isLoading}
      actionLabel={t('Charge in settlement')}
      variant="subAction"
      width="30rem"
    >
      <Typography paragraph>{t('The billing account balance will be charged to the merchant settlement account with the same currency as the invoice. Do you want to continue?')}</Typography>
    </ModalButton>
  );
};

export default BalanceInSettlementButton;
