import React from 'react';
import { Loading, useRecordContext } from 'react-admin';

import { startCase, isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

import { isDefined } from '../../utils/validators';

import { useConstantContext } from '../ConstantsContext';

import StatusChip, { colors } from '../designSystem/StatusChip';

const InvoiceStatusChip = () => {
  const record = useRecordContext();
  const { status, sentAt, pdfGeneratedAt } = record;
  const { constants } = useConstantContext();
  const { t } = useTranslation();
  const { invoiceStatuses } = constants;

  if (isEmpty(invoiceStatuses)) return <Loading />;
  if (!isDefined(status)) return null;

  let chipColor;
  let chipLabel;
  if (status === invoiceStatuses.PAID) chipColor = colors.green;
  else if (status === invoiceStatuses.PENDING) {
    chipColor = colors.gray;

    if (!sentAt && pdfGeneratedAt) {
      chipLabel = t('Created');
    } else if (!sentAt && !pdfGeneratedAt) {
      chipColor = colors.red;
      chipLabel = t('Missing data');
    } else if (sentAt && pdfGeneratedAt) {
      // TODO: for billing invoice, this could also be externally sent
      chipLabel = t('Sent');
    } else if (sentAt && !pdfGeneratedAt) {
      chipLabel = t('Externally sent');
    }
  } else if (status === invoiceStatuses.LATE) chipColor = colors.red;
  else throw new Error(`Invalid invoice status ${status} for account ${record._id}`);

  return <StatusChip label={chipLabel || startCase(status)} chipColor={chipColor} fontSize="13px" />;
};

export default InvoiceStatusChip;
