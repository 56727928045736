import React, { useCallback } from 'react';

import {
  ArrayInput,
  required,
  useRecordContext,
} from 'react-admin';
import { t } from 'i18next';
import { Typography } from '@mui/material';

import useCustomRpc from '../hooks/useCustomRpc';
import useFeatureFlags from '../hooks/useFeatureFlags';
import useHasUserPermissions from '../hooks/useHasUserPermissions';

import { EditModalButton } from '../designSystem/ModalButton';
import SimpleFormIterator from '../designSystem/react-admin/SimpleFormIterator';
import InputsGroup from '../designSystem/InputsGroup';
import SelectInput from '../designSystem/react-admin/inputs/SelectInput';
import TooltipBox from '../designSystem/TooltipBox';
import TitleH2 from '../designSystem/TitleH2';
import TitleH3 from '../designSystem/TitleH3';
import TextInput from '../designSystem/react-admin/inputs/TextInput';
import CustomSuffixNumberInput from '../utils/CustomSuffixNumberInput';

import {
  bicValidation,
  emailValidation,
  ibanValidation,
  strictlyPositiveNumberValidation,
} from '../../utils/validationErrors';

import { useConstantContext } from '../ConstantsContext';

const validateRequired = [required()];

const UpdateBillingInvoicingSettings = () => {
  const record = useRecordContext();
  const [featureFlags] = useFeatureFlags();

  const { constants, choices } = useConstantContext();

  const {
    emailLayoutTypesInvoice,
    invoiceBillingLayoutTypes,
    currencies,
  } = choices;
  const {
    invoicingSettingTypes,
    userActions,
  } = constants;

  const {
    mutate: updateBillingInvoicingSettings,
    isLoading,
  } = useCustomRpc({
    path: 'my-company/invoicing-settings',
    httpMethod: 'PUT',
    shouldRefresh: true,
    errorMessage: t('Could not update the billing invoicing settings in the my company page'),
  });

  const onSubmit = useCallback((formData) => {
    updateBillingInvoicingSettings({
      invoicingSettings: {
        ...formData.billingInvoicingSettings,
        type: invoicingSettingTypes.BILLING,
      },
    });
    return true;
  }, [invoicingSettingTypes, updateBillingInvoicingSettings]);

  const canEdit = useHasUserPermissions(
    constants,
    userActions.MANAGE_INVOICES,
  );

  if (!featureFlags.enableBillingInvoicing || !canEdit) return null;

  return (
    <EditModalButton
      modalTitle={t('Billing invoicing settings')}
      onClick={onSubmit}
      disabled={isLoading}
      fullWidth
      withForm
      formDefaultValue={record}
      width="47.5rem"
    >
      <Typography>
        {t('Billing invoices is sent to the merchant with the monthly factoring fee and administration cost for financing. You always have the option to charge merchant in settlement.')}
      </Typography>
      <TooltipBox
        tooltip={t('Due date is calculated from invoice creation date')}
        marginTop="1.5rem"
        alignItems="center"
      >
        <TitleH2 title={t('Payment terms')} />
      </TooltipBox>
      <InputsGroup layout="column">
        <CustomSuffixNumberInput
          source="billingInvoicingSettings.termsOfPayment"
          label={t('Select terms of payment')}
          customSuffix={t('days')}
          placeholder={t('E.g 30')}
          validate={[strictlyPositiveNumberValidation, ...validateRequired]}
        />
      </InputsGroup>
      <InputsGroup>
        <TooltipBox
          tooltip={t('Some merchants have orders in different currency\'s, requiring different, VAT numbers, and IBAN numbers. If this doesn\'t apply to your merchant, you only need one set of settings. You will need to decide this manually.')}
          marginTop="1.5rem"
          alignItems="center"
        >
          <TitleH2 title={t('Currency & payment settings')} />
        </TooltipBox>
        <ArrayInput
          source="billingInvoicingSettings.currencySettings"
          label={false}
          validate={validateRequired}
        >
          <SimpleFormIterator addButtonLabel={t('Add currency')} itemLabel={t('Currency')} enableBackgroundColor>
            <InputsGroup layout="column">
              <SelectInput source="currency" label={t('Apply to currency')} choices={currencies} validate={validateRequired} />
            </InputsGroup>
            <InputsGroup>
              <TextInput
                source="invoiceText"
                label={t('Additional text')}
                placeholder={t('E.g "Thank you for your trust” or “Payment terms as agreed"')}
                multiline
                emptyText="-"
              />
            </InputsGroup>
            <TitleH3 title={t('Payment method')} marginTop="2rem" />
            <InputsGroup layout="column">
              <TextInput source="iban" label="IBAN" validate={[ibanValidation]} />
              <TextInput source="bic" label="BIC" validate={[bicValidation]} />
              <TextInput source="accountHolderName" label={t('Account holder name')} />
              <TextInput source="bankgiro" label={t('Bankgiro')} />
            </InputsGroup>
          </SimpleFormIterator>
        </ArrayInput>
      </InputsGroup>
      <InputsGroup layout="column" title={t('Invoice sender')}>
        <TextInput source="billingInvoicingSettings.invoiceEmail" label={t('Invoice email')} type="email" validate={[emailValidation]} />
        <TextInput source="billingInvoicingSettings.invoiceAddress.streetAddress" label={t('Street address')} />
        <TextInput source="billingInvoicingSettings.invoiceAddress.additionalStreetAddress" label={t('Additional street address')} />
        <TextInput source="billingInvoicingSettings.invoiceAddress.town" label={t('Town')} />
        <TextInput source="billingInvoicingSettings.invoiceAddress.postcode" label={t('Postcode')} />
        <SelectInput
          label={t('Invoice layout')}
          source="billingInvoicingSettings.invoiceLayout"
          choices={invoiceBillingLayoutTypes}
          defaultValue={invoiceBillingLayoutTypes[0].id}
          validate={validateRequired}
        />
        <SelectInput
          label={t('Email layout')}
          source="billingInvoicingSettings.emailLayout"
          choices={emailLayoutTypesInvoice}
          defaultValue={emailLayoutTypesInvoice[0].id}
          validate={validateRequired}
        />
      </InputsGroup>
    </EditModalButton>
  );
};

export default UpdateBillingInvoicingSettings;
