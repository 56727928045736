import React, { useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';

import {
  useNotify,
  useRecordContext,
  useRedirect,
} from 'react-admin';

import useCustomRpc from '../hooks/useCustomRpc';
import useFeatureFlags from '../hooks/useFeatureFlags';

import ModalButton from '../designSystem/ModalButton';
import WarningBox from '../designSystem/WarningBox';

const SendInvoiceButton = () => {
  const { id: accountId } = useRecordContext();
  const { t } = useTranslation();
  const record = useRecordContext();
  const notify = useNotify();
  const redirect = useRedirect();
  const [featureFlag] = useFeatureFlags();

  const path = `account/${accountId}/send-billing-invoice`;

  const showNotification = useCallback(() => {
    notify(
      t('The invoice is successfully sent to the merchant\'s invoice email'),
      {
        multiLine: true,
        autoHideDuration: 5000,
      },
    );
  }, [notify, t]);

  const {
    mutate: sendBillingInvoice,
    isLoading,
  } = useCustomRpc({
    path,
    httpMethod: 'PUT',
    shouldRefresh: true,
    onSuccess: showNotification,
    errorMessage: t('Could not send the invoice'),
  });

  const handleClick = useCallback(() => {
    sendBillingInvoice();
    return true;
  }, [sendBillingInvoice]);

  const handleRedirect = useCallback(() => {
    redirect('/notification');
  }, [redirect]);

  const hasSingleInvoice = record.invoices && record.invoices.length === 1;
  const hasPDFGenerated = hasSingleInvoice && record.invoices[0].pdfGeneratedAt;
  const hasSent = hasSingleInvoice && record.invoices[0].sendAt;

  // TODO: Block partner billing until we make the decision
  const isPartnerBilling = record.partnerId;

  if (hasSent
    || isPartnerBilling
    || !featureFlag.enableBillingInvoicing) return null;

  return hasPDFGenerated
    ? (
      <ModalButton
        modalTitle={t('Send invoice')}
        openButtonLabel={t('Send invoice')}
        onClick={handleClick}
        disabled={isLoading}
        actionLabel={t('Send invoice')}
        variant="subAction"
        width="30rem"
      >
        <Typography paragraph>{t('The invoice for the billing will be sent to the merchant email address. Once sent, this action can not be undone. Do you want to continue?')}</Typography>
      </ModalButton>
    ) : (
      <ModalButton
        modalTitle={t('Failed to send invoice')}
        openButtonLabel={t('Send invoice')}
        variant="subAction"
        width="30rem"
        onClick={handleRedirect}
        disabled={isLoading}
        actionLabel={t('View notifications')}
      >
        <Typography paragraph>{t('You cannot send this invoice due to incomplete information. To resolve this, please review the billing invoicing settings in \'My company\' or the merchant\'s \'Financing settings\' to ensure all necessary details are provided')}</Typography>
        <WarningBox warningText={t('Details are missing, more information in notifications')} />
      </ModalButton>
    );
};

export default SendInvoiceButton;
